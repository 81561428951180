export default {
  props: {
    value: {
      type: [String, Number, Array]
    }
  },
  methods: {
    handleClick() {
      this.$emit('click', this.value)
    }
  }
}
